export const state = () => ({
    story: null,
});

export const mutations = {
    setStory(state, story) {
        state.story = story;
    },
};

export const getters = {
    getStory: (state) => state.story,
};
