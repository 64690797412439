import {Middleware} from '@nuxt/types';
import {Logger} from '~/helpers/logger';
import {handleMaintenancePageError} from '~/helpers/maintenancePageErrorHandler';
import {RoutableInterface} from '~/modules/GraphQL/types';
import {ssrRef} from "@nuxtjs/composition-api";

const urlResolverMiddleware: Middleware = async (context) => {
  const {path, params, query} = context.route;

  // Clean up the URL for resolving
  const clearUrl = path.replace(/[a-z]+\/[cp|]\//gi, '').replace(`/${context.i18n.locale}`, '');

  Logger.debug('middleware/url-resolver', clearUrl);

  try {
    // Check for Magento route first
    const {data, errors} = await context.app.$vsf.$magento.api.route(clearUrl);

    Logger.debug('middleware/url-resolver/result', {data, errors});

    const results: RoutableInterface | null = data?.boldRoute ?? null;

    // if no results then check if it is Storyblok story
    if (!results || errors?.length) {
      try {
        const cacheVersion = ssrRef(null, 'getCacheVersion-cacheVersion');

        if (context.ssrContext) {
          // @ts-ignore
          cacheVersion.value = context.ssrContext.storyblokCacheVersion;
        }

        const storyblokResponse = await context.app.$storyapi.get(`cdn/stories/${clearUrl}`, {
          version: context.$config.storyblokMode,
          cv: cacheVersion.value || undefined,
        });

        // Commit route data for STORYBLOK_PAGE if a story exists. Will be used on storyblok page component so we do not
        // have to make 2 requests to storyblok
        if (storyblokResponse.data?.story) {
          // Store the Storyblok story data in the Vuex store
          context.store.commit('storyblok/setStory', storyblokResponse.data.story);
          context.store.commit('page/setRouteData', {type: 'STORYBLOK_PAGE', uid: clearUrl});

          return;
        }

        // Check if it's a 404 error from Storyblok, then we can throw HTTP 404, else throw error
      } catch (storyblokError) {
        if (storyblokError.response && storyblokError.response.status === 404) {
          Logger.debug('middleware/url-resolver/storyblok', `No story found for slug: ${clearUrl}`);
          context.res.statusCode = 404;

          return context.error({statusCode: 404, message: 'Page not found'});
        }

        throw storyblokError;
      }

      // If we reach here, no Magento route or Storyblok story was found, throw 404
      context.res.statusCode = 404;

      return context.error({statusCode: 404, message: 'Page not found'});
    }

    // Commit route data for Magento route
    context.store.commit('page/setRouteData', results);
  } catch (err) {
    handleMaintenancePageError(err, context.error);
  }
};

export default urlResolverMiddleware;
